import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import { SETTINGS } from '../constant/setting'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ttxid: 'tx0LCHIQnvsUjUAGY6no28VrzpEstO',
    token: '133DB0B09F8A2AE88148A1E75BEAF4716412CEE7',
    timestamp: '1599546229546',
    isLoading: false,
    error: false,
    products: [],
    categorys: new Set(),
    styles: new Set()
  },
  getters: {
    getProducts: state => (category, style) => {
      return state.products
        .filter(function(item) {
          if (category === '') {
            return true
          }
          return item.category === category
        })
        .filter(function(item) {
          if (style === '') {
            return true
          }
          return item.style === style
        })
    },
    getProductById: state => productId => {
      return state.products.find(item => item.id == padLeft(productId, 4))
    },
    getHighestPrice: state => {
      if (state.products.length > 0) {
        return state.products.sort(function(a, b) {
          return b.price - a.price
        })[0].price
      } else {
        return 0
      }
    },
    getCategorys: state => {
      return state.categorys
    },
    getStyles: state => {
      return state.styles
    }
  },
  mutations: {
    toggleLoading(state) {
      state.isLoading = !state.isLoading
    },
    startLoading(state) {
      state.isLoading = true
    },
    endLoading(state) {
      state.isLoading = false
    },
    cleanError(state) {
      state.error = false
    },
    setError(state) {
      state.error = true
    },
    setProducts(state, payload) {
      state.products = payload.data
      for (let i in payload.data) {
        state.categorys.add(payload.data[i].category)
        state.styles.add(payload.data[i].style)
      }
    }
  },
  actions: {
    async getImageUploadInfo({ state }) {
      // commit(FORTUNE_MUTATION.ADD_LOADING)
      let payload = {}
      payload.ttxid = state.ttxid
      payload.token = state.token
      payload.timestamp = state.timestamp
      payload.project = SETTINGS.PROJECT_NAME

      try {
        let res = await axios.get(
          // 'https://api.taotaoxi.net/face-reading/upload-info',
          'https://api.taotaoxi.net/face-reading-must/upload-info',
          {
            params: payload
          }
        )
        return res.data
      } catch (err) {
        throw err
      } finally {
        // commit(FORTUNE_MUTATION.MINUS_LOADING)
      }
    },
    async getResult2({ dispatch }, payload) {
      let imageId = payload.imageId
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/finance/result/' + imageId
        )

        if (payload.times > 30) {
          throw 'timeout'
        } else if (res.data.message === 'PENDING') {
          payload.times++
          await new Promise(resolve => setTimeout(resolve, 1000))
          return await dispatch('getResult2', payload)
        } else {
          return res.data
        }
      } catch (err) {
        throw err
      } finally {
        // commit(FORTUNE_MUTATION.MINUS_LOADING)
      }
    },
    async init({ commit }) {
      var postData = {}
      // postData.ttxid = state.ttxid
      // postData.timestamp = state.timestamp
      // postData.token = state.token
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/finance/product',
          { params: postData }
        )
        commit('setProducts', res)
      } catch (err) {
        throw err
      }
    },
    async getStatistics({ state }) {
      var payload = {}
      payload.ttxid = state.ttxid
      payload.timestamp = state.timestamp
      payload.token = state.token
      try {
        let res = await axios.get(
          'https://api.taotaoxi.net/face-reading-must/finance/statistics',
          { params: payload }
        )

        return res.data
      } catch (err) {
        throw err
      }
    },
    async postTrace({}, payload) {
      let requestData = {
        category: payload.category,
        ttxImageId: payload.imageId,
        productId: payload.productId,
        productName: payload.productName,
        project: SETTINGS.PROJECT_NAME
      }
      await axios.post(
        'https://api.taotaoxi.net/face-reading-must/finance/trace',
        requestData
      )
    }
  },
  modules: {}
})

// async function delay(ms) {
//   // return await for better async stack trace support in case of errors.
//   return await new Promise(resolve => setTimeout(resolve, ms))
// }

function padLeft(str, len) {
  str = '' + str
  return str.length >= len
    ? str
    : new Array(len - str.length + 1).join('0') + str
}
