<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column align-center">
      <div class="top d-flex justify-center align-center">
        <h1 class="title">{{ product.category }}介紹</h1>
      </div>
      <div class="bottom flex-1 d-flex flex-column align-center">
        <h1 class="title"></h1>
        <h2 class="subtitle">{{ product.name }}</h2>
        <div class="flex-1 description-wrapper">
          <div class="text description-text">{{ product.description }}</div>
          <div class="text"></div>
          <div class="text">
            近三個月投資績效：{{ (product.performance * 100).toFixed(2) }} %
          </div>
        </div>
        <div class="d-flex">
          <div class="button-wrapper">
            <img
              class="button"
              src="@/assets/page-4-cancel.svg"
              @click="back()"
            />
          </div>
          <div class="button-wrapper">
            <img class="button-hover" src="@/assets/page-4-hover.svg" alt="" />
            <img
              class="button"
              src="@/assets/page-4-check.svg"
              @click="openDialog()"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column align-center">
      <div
        class="product-img image"
        :style="{
          backgroundImage: 'url(' + getProductImg(product.id) + ')'
        }"
      ></div>
    </section>

    <div id="dialog1" class="dialog" v-if="showDialog">
      <img class="bg" src="@/assets/page-4-dialog1.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img
          class="button"
          src="@/assets/page-4-cancel2.svg"
          @click="cancelDialog()"
        />
        <img
          class="button"
          src="@/assets/page-4-check2.svg"
          @click="openDialog2()"
        />
      </div>
    </div>
    <div id="dialog2" class="dialog" v-if="showDialog2">
      <img class="bg" src="@/assets/page-4-dialog2.svg" alt="" />
      <div class="button-wrapper d-flex justify-center align-center">
        <img class="button" src="@/assets/page-4-exit.svg" @click="toHome()" />
      </div>
    </div>
  </main>
</template>

<script>
// import Page7Price from '@/components/Page7Price'

export default {
  name: 'Detail',
  data() {
    return {
      showDialog: false,
      showDialog2: false
    }
  },
  // components: { Page7Price },
  beforeRouteEnter(to, from, next) {
    if (typeof from.params.imageId === 'undefined') {
      next({ name: 'Home' })
    }
    next()
  },
  computed: {
    product: function() {
      return this.$store.getters.getProductById(this.$route.params.productId)
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true
    },
    cancelDialog() {
      this.showDialog = false
    },
    openDialog2() {
      this.sendTrace()
      this.showDialog = false
      this.showDialog2 = true
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    getProductImg: function(filename) {
      return require('../assets/product/' + filename + '.jpg')
    },
    back: function() {
      this.$router.go(-1)
    },
    sendTrace: function() {
      this.$store.dispatch('postTrace', {
        category: this.product.category,
        imageId: this.$route.params.imageId,
        productId: this.$route.params.productId,
        productName: this.product.name
      })
    }
  }
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-4-background.svg');
  /* background-image: url('@/assets/page-4-background-1.jpg'); */
}
/* dialig */
.dialog {
  position: fixed;
  top: 52.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 755px;
  z-index: 20;
}
.dialog .button-wrapper {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.dialog .button-wrapper .button {
  height: 65px;
  margin: 0 40px;
}
/* cards */
.card {
  position: fixed;
  top: 19%;
  height: 65.2vh;
  box-sizing: border-box;
  /* background-color: #c73434; */
  /* opacity: 0.8; */
}
#card1 {
  left: 9%;
  width: 28vw;
  padding-top: 2%;
}
#card2 {
  left: 37%;
  width: 55vw;
  padding: 1.5% 2%;
}
#card1 .top {
  height: 24%;
}
#card1 .top .title {
  font-size: 75px;
  font-weight: normal;
  color: #2c2b5a;
  margin: 0;
}
#card1 .bottom .title {
  font-size: 72px;
  font-weight: normal;
  color: #2c2b5a;
  margin: 0;
  margin-top: 33px;
}
#card1 .bottom .subtitle {
  margin: 0;
  margin-bottom: 10px;
  font-size: 37px;
  color: white;
  font-weight: normal;
  width: 420px;
  text-align: center;
}
#card1 .bottom .text {
  color: #330066;
  font-size: 24px;
  text-align: center;
}
#card1 .button-wrapper {
  position: relative;
  margin-bottom: 5px;
}
#card1 .button-wrapper .button {
  height: 50px;
  margin: 0 5px;
}
#card1 .button-wrapper .button-hover {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  width: 178px;
}
.description-wrapper {
  width: 420px;
}
.description-text {
  height: 240px;
  overflow-y: scroll;
  margin-bottom: 10px;
  text-align: justify;
}
#card2 .image {
  border: 5px solid #2d2b5b;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
</style>
