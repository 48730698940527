<template>
  <svg
    id="_Layer_"
    data-name="&lt;Layer&gt;"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 422.61 171.27"
  >
    <rect class="cls-1" x="187.37" y="3" width="232.24" height="165.27" />
    <line class="cls-1" x1="261.88" y1="3" x2="261.88" y2="168.27" />
    <line class="cls-1" x1="187.37" y1="58.6" x2="261.88" y2="58.6" />
    <line class="cls-1" x1="187.37" y1="114.72" x2="261.88" y2="114.72" />
    <line class="cls-1" x1="261.88" y1="85.64" x2="275.61" y2="85.64" />
    <line class="cls-1" x1="337.89" y1="3" x2="337.89" y2="17.11" />
    <line class="cls-1" x1="337.89" y1="154.8" x2="337.89" y2="168.92" />
    <line class="cls-1" x1="405.67" y1="85.64" x2="419.61" y2="85.64" />
    <circle class="cls-2" cx="39.65" cy="42.07" r="39.65" />
    <circle class="cls-3" cx="39.65" cy="42.07" r="32.48" />
    <path
      class="cls-4"
      d="M34.45,45.4,30.9,46.57v9c0,1.88-.41,3.13-1.24,3.77l-.37.22c-1,.51-2.93.77-5.71.77a16.77,16.77,0,0,0-1.24-4.1c.95,0,2.12.07,3.51.07a.79.79,0,0,0,.66-.7V48l-4.1,1.24-1.17-4.65q1.28-.23,5.27-1.17V36.47H21.79V32.34h4.72V25.46H30.9v6.88h3.7v4.13H30.9v5.82L34,41.56Zm17.09-4.17,2.93,1.17a24.35,24.35,0,0,1-6.11,10.8,27.78,27.78,0,0,0,8.71,3.11,17.39,17.39,0,0,0-2.93,4.06A27.86,27.86,0,0,1,44.7,56.2a33.62,33.62,0,0,1-10.07,4.25,14,14,0,0,0-2.27-4.1,27.74,27.74,0,0,0,8.79-3.22,26.84,26.84,0,0,1-4.5-6.78l3.18-1h-4.1v-4h.73A12,12,0,0,0,34,38.52l-.44-.36a7.8,7.8,0,0,0,4.13-6.67,8.44,8.44,0,0,0,0-.87V26.81H51v8.13c.15.68.33,1,.55,1.09h1.07c.29,0,.48-.28.58-.84s.12-1.49.15-2.93A10.33,10.33,0,0,0,57,33.84q-.33,4.24-1.65,5.27a3.58,3.58,0,0,1-2.23.62H50.7c-1.78,0-2.93-.49-3.44-1.46a7.62,7.62,0,0,1-.55-3.3V30.91H41.92Q41.8,38,36.57,41.38H50.7Zm-3,4.14h-7.8a19.14,19.14,0,0,0,3.92,5.19A18.28,18.28,0,0,0,48.58,45.37Z"
    />
    <circle class="cls-2" cx="118.95" cy="42.07" r="39.65" />
    <circle class="cls-3" cx="118.95" cy="42.07" r="32.48" />
    <path
      class="cls-4"
      d="M131.14,54.11h-6.48a109,109,0,0,1,10.69,4.4l-5.16,2a72.25,72.25,0,0,0-9.78-4.43l3.41-1.94h-11l3.44,1.5a42.36,42.36,0,0,1-11.57,4.87l-.14,0a36.39,36.39,0,0,0-3.3-3.26,39.19,39.19,0,0,0,10.32-3.15h-5.52V38.89h7.83a12.1,12.1,0,0,0-1.54-2.34q-6.84,1.89-10.25,2.81l-1.24-3.62q3.73-.69,11.31-2.49l.37,3q5.74-1,7.5-3.37a7.39,7.39,0,0,0,.88-1.83h-2.71a25,25,0,0,1-3,3,9.83,9.83,0,0,0-3.33-2.09,16.06,16.06,0,0,0,5.49-6.51l4,.62a16.21,16.21,0,0,1-1,1.83H131.4l.69-.11,3,.77a27.47,27.47,0,0,1-3.59,5.64L128,33.11c.58-.76,1.07-1.46,1.46-2.09h-4.17a6.5,6.5,0,0,0,3.81,3.81,25,25,0,0,0,6.69,1.57,9.85,9.85,0,0,0-2.12,3.44,1.71,1.71,0,0,1-.07.18c-1.1-.17-1.93-.33-2.49-.47ZM112.22,28v3.37h-10V28Zm-1.83,13.58v1.61H126.6V41.56Zm0,4.14v1.61H126.6V45.7Zm0,4.1v1.64H126.6V49.8Zm6.11-10.91h12.55a11.94,11.94,0,0,1-5.74-4Q121.29,37.5,116.5,38.89Z"
    />
    <circle class="cls-2" cx="39.65" cy="126.45" r="39.65" />
    <circle class="cls-3" cx="39.65" cy="126.45" r="32.48" />
    <path
      class="cls-4"
      d="M26,111.57h27q-.3,22.55.73,27.05c.24,1,.53,1.59.88,1.61s.55-1.62.62-4.87V135a18.72,18.72,0,0,0,2.49,3.47q-.44,5.13-2.12,6.19a2.75,2.75,0,0,1-1.47.4q-3.51,0-4.68-7.94-.81-5.49-.81-20.2v-1.21H30.28v8.89q0,14.31-4.76,20.57A12.61,12.61,0,0,0,22.34,143l-.4-.19q3.84-5.16,4.06-16.1c0-.66,0-1.36,0-2.09Zm16.72,23.87,3.37-1.1a28.16,28.16,0,0,1,3.37,7.87c0,.17.08.33.11.47L46,143.85a22.74,22.74,0,0,0-.66-2.52q-9,.81-15.15,1.28l-.29-4q2.78-.11,7.5-.4v-3.92H31.56V123.39h5.82v-2.26c-1.85.14-3.73.25-5.63.32a13.43,13.43,0,0,0-1.1-3.29q10-.59,14.6-1.87l2.89,3.19a63.19,63.19,0,0,1-6.91,1.28v2.63H47.3v10.87H41.23V138l2.7-.22A21.62,21.62,0,0,0,42.76,135.44Zm-7.83-4.54h2.45v-4.14H34.93Zm8.75-4.14H41.23v4.14h2.45Z"
    />
    <circle class="cls-2" cx="118.95" cy="126.45" r="39.65" />
    <circle class="cls-3" cx="118.95" cy="126.45" r="32.48" />
    <path
      class="cls-4"
      d="M131.47,113.84l2.78,1.32a28.46,28.46,0,0,1-5.71,10,25.68,25.68,0,0,0,8.49,4.17,13.88,13.88,0,0,0-2.6,3.47,2.17,2.17,0,0,1-.11.22l-.69-.25V145h-4.28v-1.21h-7.84v1.32h-4.17v-12a17.93,17.93,0,0,1-1.9.8,17.59,17.59,0,0,0-1.91-3l-.36.47a34.16,34.16,0,0,0-2-3.62V145h-4.21V130.82a30.38,30.38,0,0,1-3.73,7.69,23,23,0,0,0-2.05-4.06A32.6,32.6,0,0,0,106,124.09c.21-.78.41-1.52.58-2.23H102v-4.1h4.9v-7.69h4.21v7.69h4.21v2a26.54,26.54,0,0,0,6.33-9.73l4.21,1.13c-.32.83-.72,1.77-1.21,2.82h6ZM115.58,128l-1.28,1.76a29.86,29.86,0,0,0,8.27-4.72,37,37,0,0,1-2.78-3.66,27.58,27.58,0,0,1-2.78,2.89,25.5,25.5,0,0,0-2.71-2.45h-3.18v.91q2.37,2.57,4.28,5A1.51,1.51,0,0,1,115.58,128Zm5.68,3h8.82a26.19,26.19,0,0,1-4.5-3.07A34.65,34.65,0,0,1,121.26,131.08Zm.25,8.86h7.84v-5.09h-7.84Zm7.1-22.15h-6.14l-.26.37a25.68,25.68,0,0,0,3.22,4.21A22.72,22.72,0,0,0,128.61,117.79Z"
    />
    <text class="cls-5" transform="translate(287.3 117.6)">{{ score }}</text>
    <text class="cls-6" transform="translate(206.81 45.37)">
      {{ c.charAt(0) }}
    </text>
    <text class="cls-6" transform="translate(206.81 100.66)">
      {{ c.charAt(1) }}
    </text>
    <text class="cls-6" transform="translate(206.81 155.97)">
      {{ c.charAt(2) }}
    </text>
  </svg>
  <!-- <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 400.5 163.5"
    style="enable-background:new 0 0 400.5 163.5;"
    xml:space="preserve"
  >
    <g id="圖層_2_1_">
      <rect x="161.7" y="0.8" class="st0" width="238" height="162.5" />
      <rect x="237.7" y="0.8" class="st0" width="162" height="162" />
      <line class="st0" x1="161.2" y1="113.8" x2="237.2" y2="113.8" />
      <line class="st0" x1="316.2" y1="0.2" x2="316.2" y2="14.8" />
      <line class="st0" x1="316.2" y1="148.2" x2="316.2" y2="162.8" />
      <line class="st0" x1="401.5" y1="81.5" x2="387" y2="81.5" />
      <line class="st0" x1="253.4" y1="81.5" x2="238.9" y2="81.5" />
      <line class="st0" x1="161.2" y1="53.8" x2="237.2" y2="53.8" />
      <circle class="st1" cx="34.7" cy="113.2" r="32.7" />
      <circle class="st2" cx="34.7" cy="113.2" r="28.9" />
      <circle class="st1" cx="35.1" cy="47.2" r="32.7" />
      <circle class="st2" cx="35.1" cy="47.2" r="28.9" />
      <circle class="st1" cx="98.5" cy="47.2" r="32.6" />
      <circle class="st2" cx="98.5" cy="47.2" r="28.9" />
      <circle class="st1" cx="100" cy="113.2" r="32.7" />
      <circle class="st2" cx="100" cy="113.2" r="28.9" />
      <path
        class="st3"
        d="M30.6,31.9c-0.9,2.4-2,4.8-3.3,7l0.2,23.1h-4.2L23,45.2c-0.7,0.8-1.5,1.7-2.2,2.5c-0.7-1.5-1.5-2.9-2.4-4.3
		c3.6-3.6,6.3-7.9,8-12.7L30.6,31.9z M52.1,57v3.8L29.2,61v-3.8l9.4-0.1v-6.8l-7.5,0.1v-3.8l7.5-0.1v-5.9L30,40.7v-3.8l9.5-0.1
		C38.9,35,38,33.3,37,31.6l3.9-1.3c1.1,1.6,2.1,3.4,2.8,5.2l-3.6,1.3l11.5-0.1v3.8l-8.6,0.1l0,5.9l7.6-0.1v3.8L43,50.3v6.8L52.1,57z
		"
      />
      <path
        class="st3"
        d="M92.6,38.4c-0.9,1.9-1.9,3.7-3.1,5.4l0.1,17.8h-4l-0.1-12.9c-0.8,0.9-1.7,1.8-2.7,2.5
		c-0.7-1.3-1.5-2.6-2.4-3.7c3.5-2.7,6.3-6.2,8.2-10.3L92.6,38.4z M86.3,39.3h-4.1L82.1,33l12.7-0.1c-0.2-0.7-0.5-1.4-0.9-2l4.5-1.2
		c0.5,0.9,1,2.1,1.5,3.2l12.4-0.1v5.6H108v-2.1l-21.8,0.2L86.3,39.3z M104.5,42.6c-0.3,0.8-0.6,1.6-0.9,2.3l7.8-0.1l0.1,16.4h-4.2
		v-1.2l-9.7,0.1v1.4h-4l-0.1-16.6h5.8c0.2-0.8,0.3-1.6,0.5-2.3h-7.4v-3.5l20.6-0.2v3.5L104.5,42.6z M107.2,48.2l-9.7,0.1v2.5
		l9.7-0.1L107.2,48.2z M107.3,56.7V54l-9.7,0.1v2.7L107.3,56.7z"
      />
      <path
        class="st3"
        d="M48.4,96.6c-0.1,13.3,0,25.8,1.6,25.8c0.3,0,0.5-2,0.6-4.7c0.7,1.1,1.5,2.2,2.3,3.2c-0.4,4-1.1,5.9-3.3,5.9
		c-5.1,0-5.2-12-5.3-26.4l-17.3,0.1l0.1,8c0,5.6-0.5,13.6-4.3,18.6c-1-0.9-2.2-1.6-3.4-2.1c3.5-4.6,3.8-11.3,3.8-16.4l-0.1-11.7
		L48.4,96.6z M42.1,117.2c1.5,2.3,2.6,4.8,3.3,7.5l-3.4,1.1c-0.2-0.6-0.4-1.4-0.6-2.3c-5.2,0.5-10.5,0.9-14.4,1.3l-0.3-3.6
		c1.9-0.1,4.4-0.2,7.1-0.4v-3.5h-5.5l-0.1-9.8h5.5v-2.3H28v-3.2l15-0.1v3.2h-5.8v2.3H43l0.1,9.8h-5.7v3.3l2.6-0.1
		c-0.3-0.7-0.7-1.4-1.2-2.1L42.1,117.2z M33.7,114.2v-3.7h-2.3v3.7H33.7z M37.3,110.5v3.7h2.3v-3.7H37.3z"
      />
      <path
        class="st3"
        d="M113.1,99.1c-1.4,3.1-3.5,5.9-6.1,8.1c2.4,1.1,5,1.9,7.7,2.4c-0.6,1.2-1.1,2.5-1.3,3.8c-3.4-0.8-6.7-2-9.7-3.8
		c-2.6,1.6-5.3,2.9-8.2,3.8l16.3-0.1l0.1,12.7h-4.2v-1.7l-8.7,0.1v1.9h-4l-0.1-12.6l-1,0.3c-0.3-0.8-0.8-1.5-1.3-2.2l-0.8,1
		c-0.4-0.8-1.2-2.1-1.9-3.3l0.1,17h-4l-0.1-13.4c-0.7,2.5-1.8,4.9-3.1,7.2c-0.5-1.4-1.2-2.7-2-4c1.9-2.6,3.6-7.2,4.5-11.2h-3.8v-3.7
		h4.3l-0.1-6.3h4v6.3h3.2v1.9c2.6-2.3,4.6-5.2,5.8-8.5l3.7,1c-0.3,0.7-0.7,1.6-1,2.3l8.3-0.1l0.7-0.1L113.1,99.1z M100.3,107.2
		c-1.1-0.9-2.1-2-3-3.1c-0.6,0.8-1.3,1.5-2,2.1c-0.7-1.1-1.5-2.1-2.3-3.1v1.7h-3.1c1.5,1.5,3,3.1,4.3,4.8
		C96.3,109.1,98.4,108.3,100.3,107.2z M98.9,116.9v4l8.7-0.1v-4L98.9,116.9z M99.7,101.4c1,1.5,2.3,2.8,3.8,3.8
		c1.5-1.1,2.8-2.4,3.8-3.9L99.7,101.4z"
      />
    </g>
    <text transform="matrix(1 0 0 1 176.4028 40.3337)">
      <tspan x="0" y="0" class="st3 st4 st5">{{ c.charAt(0) }}</tspan>
      <tspan x="0" y="56.6" class="st3 st4 st5">{{ c.charAt(1) }}</tspan>
      <tspan x="0" y="113.3" class="st3 st4 st5">{{ c.charAt(2) }}</tspan>
    </text>
    <text transform="matrix(1 0 0 1 260.45 116.7201)" class="st3 st4 st6">
      {{ score }}
    </text>
  </svg> -->
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 99
    },
    category: {
      type: String,
      default: ''
    }
  },
  computed: {
    c: function() {
      return this.category
    }
  }
}
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: #eebd5d;
  stroke-miterlimit: 10;
  stroke-width: 6px;
}

.cls-2 {
  fill: #eebd5d;
}

.cls-3 {
  fill: #ff8c00;
}

.cls-4,
.cls-5,
.cls-6 {
  fill: #fff;
}

.cls-5 {
  font-size: 93.7px;
}

.cls-5,
.cls-6 {
  font-family: TaipeiSansTCBeta-Bold, Taipei Sans TC Beta;
  font-weight: 700;
}

.cls-6 {
  font-size: 37.48px;
}
/* .st0 {
  fill: none;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #009aff;
}
.st2 {
  fill: #009aff;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.st3 {
  fill: #ffffff;
}
.st4 {
  font-family: 'NotoSansCJKtc-Bold-B5pc-H';
}
.st5 {
  font-size: 40.527px;
}
.st6 {
  font-size: 97.4197px;
} */
</style>
