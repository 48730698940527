<template>
  <div id="app" @click="full()">
    <div id="alert" @click="cleanError()" v-if="isError">
      <div class="info">未偵測到人臉，請重新拍照</div>
    </div>
    <div id="loading-bg" v-if="isLoading">
      <img class="anim-img" src="@/assets/loading.gif" alt="" />
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted: function() {
    this.$store.dispatch('init')
  },
  computed: {
    isLoading: function() {
      return this.$store.state.isLoading
    },
    isError: function() {
      return this.$store.state.error
    }
  },
  methods: {
    cleanError: function() {
      this.$store.commit('cleanError')
      if (this.$route.name !== 'Home') {
        this.$router.push({
          name: 'Home'
        })
      }
    },
    full: function() {
      // if (process.env.NODE_ENV === 'production')
      document.body.requestFullscreen()
    }
  }
}
</script>
<style>
#loading-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

#loading-bg .anim-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 100px;
}
#alert {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

#alert .info {
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  font-size: 30px;
}
</style>
