<template>
  <main id="main">
    <!-- <div> -->
    <div id="dashboard" class="d-flex">
      <section id="col1" class="col d-flex flex-column">
        <h1 class="title">類型搜尋</h1>
        <h2 class="subtitle">條件設定</h2>
        <div class="flex-1 d-flex flex-column align-center">
          <select class="select" v-model="f2">
            <option value="" selected disabled>理財風格</option>
            <option value="">全部</option>
            <option v-for="style in getStyles" :key="style" :value="style">
              {{ style }}
            </option>
          </select>
          <select class="select" v-model="f1">
            <option value="" selected disabled>產品類型</option>
            <option value="">全部</option>
            <option
              :value="category"
              v-for="category in getCategorys"
              :key="category"
            >
              {{ category }}
            </option>
          </select>
        </div>
        <div class="d-flex justify-center">
          <page3-reset class="button" @back="back()" />
        </div>
      </section>
      <section id="col2" class="col">
        <div id="table-wrapper" class="tableFixHead">
          <table id="table" border="1">
            <thead>
              <tr>
                <th>投資標的名稱</th>
                <th>理財風格</th>
                <th>產品類型</th>
                <th>投資績效</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="prod"
                v-for="product in products"
                :key="product.id"
                @click="toDetail(product.id)"
              >
                <td>
                  <div class="d-flex justify-center align-center">
                    {{ product.name }}
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center justify-center">
                    {{ product.style }}
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center justify-center">
                    {{ product.category }}
                  </div>
                </td>
                <td>
                  <div class="d-flex align-center justify-center">
                    {{ (product.performance * 100).toFixed(2) }} %
                  </div>
                </td>
                <td>
                  <div
                    class="button-wrapper d-flex align-center justify-center"
                  >
                    <button class="button">選我</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <!-- </div> -->
  </main>
</template>

<script>
import Page3Reset from '@/components/Page3Reset'

// const MAX_PRICE = 1000000

export default {
  name: 'More',
  data() {
    return {
      f1: '',
      f2: ''
    }
  },
  components: { Page3Reset },
  beforeRouteEnter(to, from, next) {
    if (typeof from.params.imageId === 'undefined') {
      next({ name: 'Home' })
    }
    next()
  },
  mounted: function() {
    new Audio(require('../assets/vocal/5.mp3')).play()
  },
  computed: {
    getHighestPrice: function() {
      return this.$store.getters.getHighestPrice
    },
    products: function() {
      return this.$store.getters.getProducts(this.f1, this.f2)
    },
    priceFilter: function() {
      // let vm = this
      let max = parseInt(this.getHighestPrice / 50)
      let filterArray = [...Array(max).keys(), max]
      return filterArray
    },
    getCategorys: function() {
      return this.$store.getters.getCategorys
    },
    getStyles: function() {
      return this.$store.getters.getStyles
    }
  },
  methods: {
    getProductImg: function(filename) {
      return require('../assets/product/' + filename + '.jpg')
    },
    toDetail: function(productId) {
      this.$router.push({
        name: 'Detail',
        params: { productId: productId, imageId: this.$route.params.imageId }
      })
    },
    back: function() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/* https://stackoverflow.com/questions/21168521/table-fixed-header-and-scrollable-body */
.tableFixHead {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 2;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
/* th     { background:#eee; } */
#main {
  background-image: url('../assets/page-5-6-background.svg');
  /* background-image: url('./assert/page-5-6-background.jpg'); */
}
/* table */
#dashboard {
  position: absolute;
  top: 20%;
  width: 87%;
  left: 7.3%;
  height: 61vh;
  background: #ffaf00;
  border: 4px solid #ffaf00;
  /* opacity: 0.8; */
}
#dashboard .col {
  width: 30%;
  height: 100%;
  background: white;
}
#dashboard #col1 {
  width: 22.5%;
  border-right: 3px solid #ffaf00;
  padding-bottom: 10px;
  box-sizing: border-box;
}
#dashboard #col2 {
  width: 77.5%;
}
#dashboard #col1 .title {
  background: #ffaf00;
  color: #2d2b5b;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  font-size: 35px;
  font-weight: normal;
}
/* https://css-tricks.com/styling-a-select-like-its-2019/ */
#dashboard #col1 select.select {
  height: 60px;
  width: 315px;
  margin: 18px auto;
  font-size: 31px;
  font-weight: bold;
  color: #2d2b5b;
  padding: 5px 22px;
  box-sizing: border-box;
  border: 4px solid #2d2b5b;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
          for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      */
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232d2b5b%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.9em auto, 100%;
}

#dashboard #col1 select.select option {
  font-size: 20px;
}
#dashboard #col1 .button {
  height: 57px;
  margin: 10px;
  margin-top: 15%;
}
#dashboard #col1 .subtitle {
  color: #808080;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
  font-size: 40px;
  font-weight: normal;
  margin-top: 25px;
}
/* table */
.tableFixHead {
  height: 100%;
}
#table th,
#table td {
  border: 3px solid #ffaf00;
  text-align: center;
  background: white;
  padding: 0;
  color: #2d2b5b;
}
#table th {
  background: #ffaf00;
  height: 60px;
  margin: 0;
  text-align: center;
  font-size: 35px;
  font-weight: normal;
  border: none;
}
#table th::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -1%;
  height: 66px;
  width: 111%;
  background: inherit;
  z-index: -1;
}
#table td {
  background-color: inherit;
  color: #2d2b5b;
  font-size: 33px;
  font-weight: normal;
}

#table td:nth-child(1) {
  width: 27.5%;
}

#table td:nth-child(2) {
  width: 21%;
}

#table td:nth-child(3) {
  width: 18%;
}

#table td:nth-child(4) {
  width: 18.5%;
}

tr.prod {
  height: 150px;
}

#table .prod .button-wrapper .button {
  background: #2d2b5b;
  margin: 10px;
  height: 65px;
  color: #eebd5d;
  border: none;
  width: 166px;
  font-size: 28px;
}
</style>
