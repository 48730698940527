<template>
  <main id="main">
    <section id="card1" class="card d-flex flex-column align-center">
      <div class="top">
        <page3-attributes
          v-if="result.position"
          :img-url="imgUrl"
          :face-top="result.position.top"
          :face-left="result.position.left"
          :face-width="result.position.width"
          :face-height="result.position.height"
          :eye-tag="result.eye.tag"
          :eye-keyword="result.eye.keyword"
          :brow-tag="result.brow.tag"
          :brow-keyword="result.brow.keyword"
          :nose-tag="result.nose.tag"
          :nose-keyword="result.nose.keyword"
          :mouth-tag="result.mouth.tag"
          :mouth-keyword="result.mouth.keyword"
          :shape-tag="result.shape.tag"
          :shape-keyword="result.shape.keyword"
        />
      </div>
      <div class="bottom flex-1 d-flex flex-column align-center">
        <h2 class="title" v-if="result.fiveElement">
          【{{ result.fiveElement.motto[0] }}】【{{
            result.fiveElement.motto[1]
          }}】
        </h2>
        <div>
          <div class="text" v-if="result.shape">
            {{ result.shape.tag }}｜{{ result.shape.shortDesc }}
          </div>
          <div class="text" v-if="result.brow">
            {{ result.brow.tag }}｜{{ result.brow.shortDesc }}
          </div>
          <div class="text" v-if="result.eye">
            {{ result.eye.tag }}｜{{ result.eye.shortDesc }}
          </div>
          <div class="text" v-if="result.nose">
            {{ result.nose.tag }}｜{{ result.nose.shortDesc }}
          </div>
          <div class="text" v-if="result.mouth">
            {{ result.mouth.tag }}｜{{ result.mouth.shortDesc }}
          </div>
        </div>
      </div>
    </section>
    <section id="card2" class="card d-flex flex-column align-center">
      <div class="top d-flex justify-center align-center">
        <page3-title
          class="w-100"
          v-if="result.score"
          :score="result.score"
          :category="result.style"
        />
        <!-- <img class="w-100" src="@/assets/page-3-title.svg" alt="" /> -->
      </div>
      <div class="middle d-flex justify-center align-center">
        以下投資方式很適合你
      </div>
      <div class="bottom flex-1">
        <div
          class="prod d-flex align-center"
          v-for="(product, index) in recommendProduct"
          :key="product.id"
          :title="product.name"
          @click="selectForYou(index)"
        >
          <div class="type">{{ product.category }}</div>
          <div class="name flex-1">{{ product.name }}</div>
          <div class="button-wrapper">
            <img
              class="button-hover"
              src="@/assets/page-3-next.svg"
              v-if="index == 0"
            />
            <button class="button" @click="toDetail(product.id)">選我</button>
          </div>
        </div>
      </div>
    </section>
    <section
      id="card3"
      class="card d-flex flex-column align-center"
      v-if="result.thisIsforYouItem"
    >
      <h1 class="title">這檔{{ thisIsForYouProduct.category }}適合你</h1>
      <div class="content flex-1 d-flex flex-column align-center">
        <!-- <h1 class="title"></h1> -->
        <h2 class="subtitle">{{ thisIsForYouProduct.name }}</h2>
        <div class="info">
          <div
            class="info-image"
            :style="{
              backgroundImage:
                'url(' + getProductImg(thisIsForYouProduct.id) + ')'
            }"
          ></div>
          <!-- <img src="https://dummyimage.com/225x155/000/fff" alt="" /> -->
          <div
            class="describe"
            v-if="thisIsForYouProduct.description.length > 30"
          >
            {{ thisIsForYouProduct.description.substring(0, 30) }}...
          </div>
          <div class="describe" v-else>
            {{ thisIsForYouProduct.description }}
          </div>
        </div>
      </div>
      <div class="button-wrapper d-flex">
        <img
          class="button"
          src="@/assets/page-3-recamera.svg"
          @click="toHome()"
        />
        <img class="button" src="@/assets/page-3-more.svg" @click="toMore()" />
      </div>
    </section>
  </main>
</template>

<script>
import store from '@/store'

import Page3Attributes from '@/components/Page3Attributes'
import Page3Title from '@/components/Page3Title'

// const MOCK_IMAGE_ID = 'ttxi282edd6cad534e959d5910f02c0d5c68'

export default {
  name: 'Result',
  data() {
    return { result: {}, imgUrl: '', thisIsForyouIndex: 0 }
  },
  components: { Page3Title, Page3Attributes },
  mounted: function() {
    let vm = this
    vm.$store.commit('toggleLoading')
    // console.log(this.$route.params)
    let imageId = this.$route.params.imageId
    this.imgUrl =
      'https://upload-face-reading-must.taotaoxi.net/upload-picture/' +
      imageId +
      '.png'
    if ('undefined' === typeof this.$route.params.result) {
      store
        .dispatch('getResult2', { imageId: imageId, times: 0 })
        .then(function(res) {
          // console.log(res)
          vm.result = res
          vm.$store.commit('toggleLoading')
        })
    } else {
      this.result = this.$route.params.result
      vm.$store.commit('toggleLoading')
    }
  },
  computed: {
    recommendProduct: function() {
      let products = []
      for (let id in this.result.defaultItem) {
        products.push(
          this.$store.getters.getProductById(this.result.defaultItem[id] + 1)
        )
      }
      return products
    },
    thisIsForYouProduct: function() {
      return this.recommendProduct[this.thisIsForyouIndex]
      // return this.$store.getters.getProductById(this.result.thisIsforYouItem)
    }
  },
  methods: {
    setResult: function(data) {
      this.result = data
    },
    toHome: function() {
      this.$router.push({ name: 'Home' })
    },
    toMore: function() {
      this.$router.push({
        name: 'More',
        params: { imageId: this.$route.params.imageId }
      })
    },
    toDetail: function(productId) {
      this.$router.push({
        name: 'Detail',
        params: { productId: productId, imageId: this.$route.params.imageId }
      })
    },
    getProductImg: function(filename) {
      return require('../assets/product/' + filename + '.jpg')
    },
    selectForYou: function(index) {
      this.thisIsForyouIndex = index
    }
  }
}
</script>

<style scoped>
#main {
  background-image: url('../assets/page-3-background.svg');
  /* background-image: url('@/assets/page-3-background.jpg'); */
}
.card {
  position: fixed;
  top: 19%;
  height: 65.2vh;
  box-sizing: border-box;
  /* background-color: #c73434; */
  /* opacity: 0.8; */
}
#card1 {
  left: 5%;
  width: 29.5vw;
  padding: 1% 2% 0;
}
#card2 {
  left: 36%;
  width: 27vw;
  padding: 2% 1% 1%;
}
#card3 {
  left: 63%;
  width: 27.6vw;
  padding: 2.3% 1.6%;
  height: 64vh;
}
#card1 .top {
  width: 96%;
  height: 61%;
  position: relative;
  margin-top: 23px;
}
#card1 .bottom .title {
  margin: 0;
  margin-bottom: 7px;
  font-size: 24px;
  color: #ffc55f;
  white-space: nowrap;
}
#card1 .bottom .text {
  color: white;
  font-size: 24px;
}
#card2 .top {
  height: 35%;
  width: 100%;
  padding: 4%;
  box-sizing: border-box;
}
#card2 .middle {
  height: 13%;
  width: 100%;
  font-size: 36px;
  color: #ffc55f;
}
#card2 .bottom {
  width: 100%;
  padding: 5% 2%;
  padding-bottom: 0;
}
#card2 .prod {
  background: #eebd5d;
  margin: 16px;
  border-radius: 40px;
  height: 74px;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 25px;
  color: #2d2b5b;
}
#card2 .prod .type {
  width: 20%;
  border-right: 5px dashed #2d2b5b;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
#card2 .prod .name {
  margin: 0 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#card2 .prod .button-wrapper {
  position: relative;
}
#card2 .prod .button-wrapper .button {
  width: 89px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  background-color: #2d2b5b;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 22px;
}
#card2 .prod .button-wrapper .button-hover {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 175px;
}
#card3 .title {
  color: #ffc55f;
  font-size: 39px;
  text-align: center;
  font-weight: normal;
  margin: 10px 0;
}
#card3 .content {
  margin: 10px 0;
  background: #eebd5d;
  border-radius: 43px;
  color: #2c2b5a;
  padding: 10px;
  box-sizing: border-box;
  justify-content: flex-end;
}
#card3 .content .title {
  color: inherit;
  font-size: 52px;
  margin: 0;
}
#card3 .content .subtitle {
  margin: 0;
  font-size: 29px;
}
#card3 .content .info {
  margin-top: 10px;
  background: rgb(101, 75, 140);
  background: linear-gradient(
    0deg,
    rgba(101, 75, 140, 1) 0%,
    rgba(54, 27, 106, 1) 100%
  );
  border-radius: inherit;
  padding: 12px 30px;
  box-sizing: border-box;
  color: white;
  width: 440px;
}
#card3 .content .info .describe {
  font-size: 1.3rem;
  margin-bottom: 15px;
  text-align: justify;
}
#card3 .button-wrapper {
  justify-content: space-between;
  width: 100%;
}
#card3 .button-wrapper .button {
  height: 109px;
}
.info-image {
  height: 250px;
  background-size: cover;
  margin-top: 15px;
  margin-bottom: 15px;
  background-position: center;
  /* border-radius: inherit; */
}
</style>
