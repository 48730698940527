<template>
  <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 516 378"
    style="enable-background:new 0 0 516 378;"
    xml:space="preserve"
  >
    <defs>
      <pattern
        id="image"
        x="0"
        y="0"
        patternUnits="objectBoundingBox"
        height="1"
        width="1"
        :viewBox="viewBox"
        preserveAspectRatio="xMidYMid slice"
      >
        <image x="0" y="0" :href="imgUrl"></image>
      </pattern>
    </defs>
    <g id="圖層_2_1_">
      <circle class="st0" cx="51.5" cy="51.5" r="50.5" />
      <circle class="st0" cx="464.5" cy="67.5" r="50.5" />
      <circle class="st0" cx="63.5" cy="222.5" r="50.5" />
      <circle class="st0" cx="438.5" cy="247.5" r="50.5" />
      <circle class="st0" cx="255.5" cy="326.5" r="50.5" />
      <text transform="matrix(1 0 0 1 433.9999 88.025)" class="st1 st2 st3">
        {{ eyeKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 20.5347 72.1199)" class="st1 st2 st3">
        {{ browKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 405.1404 268.5336)" class="st1 st2 st3">
        {{ noseKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 222.9946 347.3617)" class="st1 st2 st3">
        {{ shapeKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 32.931 247.3676)" class="st1 st2 st3">
        {{ mouthKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 216.807 326.5001)" class="st4 st2 st5">
        {{ shapeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 13.0347 48.7199)"
        class="st4 st2 st5"
        v-if="browTag.length == 3"
      >
        {{ browTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 25.0347 48.7199)"
        class="st4 st2 st5"
        v-else
      >
        {{ browTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 426.4999 64.6351)"
        class="st4 st2 st5"
        v-if="eyeTag.length == 3"
      >
        {{ eyeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 438.4999 64.6351)"
        class="st4 st2 st5"
        v-else
      >
        {{ eyeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 398.6404 244.7636)"
        class="st4 st2 st5"
        v-if="noseTag.length == 3"
      >
        {{ noseTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 411.6404 244.7636)"
        class="st4 st2 st5"
        v-else
      >
        {{ noseTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 25.441 220.9476)"
        class="st4 st2 st5"
        v-if="mouthTag.length == 3"
      >
        {{ mouthTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 37.441 220.9476)"
        class="st4 st2 st5"
        v-else
      >
        {{ mouthTag }}
      </text>
      <g>
        <line class="st6" x1="142.5" y1="208.4" x2="103" y2="226.5" />
        <line class="st6" x1="257" y1="267.7" x2="257" y2="291.5" />
        <line class="st6" x1="369.7" y1="202.1" x2="412" y2="228.4" />
        <line class="st6" x1="100.1" y1="63.6" x2="135.8" y2="77.8" />
        <line class="st6" x1="375.4" y1="79.2" x2="413.5" y2="71" />
        <circle class="st6" cx="255.3" cy="135.2" r="132.5" />
        <circle cx="255.3" cy="135.2" r="132.5" fill="url(#image)" />
      </g>
    </g>
  </svg>
  <!-- <svg
    version="1.1"
    id="圖層_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 516 378"
    style="enable-background:new 0 0 516 378;"
    xml:space="preserve"
  >
    <defs>
      <pattern
        id="image"
        x="0"
        y="0"
        patternUnits="objectBoundingBox"
        height="1"
        width="1"
        :viewBox="viewBox"
        preserveAspectRatio="xMidYMid slice"
      >
        <image x="0" y="0" :href="imgUrl"></image>
      </pattern>
    </defs>
    <g id="圖層_2_1_">
      <circle class="st0" cx="51.5" cy="51.5" r="50.5" />
      <circle class="st0" cx="464.5" cy="67.5" r="50.5" />
      <circle class="st0" cx="63.5" cy="222.5" r="50.5" />
      <circle class="st0" cx="438.5" cy="247.5" r="50.5" />
      <circle class="st0" cx="255.5" cy="326.5" r="50.5" />
      <text transform="matrix(1 0 0 1 433.9999 88.025)" class="st1 st2 st3">
        {{ eyeKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 20.5347 72.1199)" class="st1 st2 st3">
        {{ browKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 405.1404 268.5336)" class="st1 st2 st3">
        {{ noseKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 222.9946 347.3617)" class="st1 st2 st3">
        {{ shapeKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 32.931 247.3676)" class="st1 st2 st3">
        {{ mouthKeyword }}
      </text>
      <text transform="matrix(1 0 0 1 216.807 326.5001)" class="st4 st2 st5">
        {{ shapeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 13.0347 48.7199)"
        class="st4 st2 st5"
        v-if="browTag.length == 3"
      >
        {{ browTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 25.0347 48.7199)"
        class="st4 st2 st5"
        v-else
      >
        {{ browTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 426.4999 64.6351)"
        class="st4 st2 st5"
        v-if="eyeTag.length == 3"
      >
        {{ eyeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 438.4999 64.6351)"
        class="st4 st2 st5"
        v-else
      >
        {{ eyeTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 398.6404 244.7636)"
        class="st4 st2 st5"
        v-if="noseTag.length == 3"
      >
        {{ noseTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 411.6404 244.7636)"
        class="st4 st2 st5"
        v-else
      >
        {{ noseTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 25.441 220.9476)"
        class="st4 st2 st5"
        v-if="mouthTag.length == 3"
      >
        {{ mouthTag }}
      </text>
      <text
        transform="matrix(1 0 0 1 37.441 220.9476)"
        class="st4 st2 st5"
        v-else
      >
        {{ mouthTag }}
      </text>
      <g>
        <line class="st6" x1="142.5" y1="208.4" x2="103" y2="226.5" />
        <line class="st6" x1="257" y1="267.7" x2="257" y2="291.5" />
        <line class="st6" x1="369.7" y1="202.1" x2="412" y2="228.4" />
        <line class="st6" x1="100.1" y1="63.6" x2="135.8" y2="77.8" />
        <line class="st6" x1="375.4" y1="79.2" x2="413.5" y2="71" />
        <circle
          class="st6"
          cx="255.3"
          cy="135.2"
          r="132.5"
          fill="url(#image)"
        />
      </g>
    </g>
  </svg> -->
</template>

<script>
// import { SSL_OP_NO_TLSv1_2 } from 'constants'
// const CX = 255.3
// const CY = 135.2
const CR = 132.5

export default {
  props: {
    imgUrl: {
      type: String,
      default:
        'https://uploadfacereading.blob.core.windows.net/upload-picture/ttxiae94eac26d304b4697ce05e366313783.png'
    },
    faceTop: {
      type: Number,
      default: 93
    },
    faceLeft: {
      type: Number,
      default: 525
    },
    faceWidth: {
      type: Number,
      default: 341
    },
    faceHeight: {
      type: Number,
      default: 379
    },
    eyeTag: { type: String, default: '定眼' },
    eyeKeyword: { type: String, default: '心思深沉' },
    browTag: { type: String, default: '點眉' },
    browKeyword: { type: String, default: '感情用事' },
    noseTag: { type: String, default: '仗義鼻' },
    noseKeyword: { type: String, default: '大方隨性' },
    mouthTag: { type: String, default: '智唇' },
    mouthKeyword: { type: String, default: '未雨綢繆' },
    shapeTag: { type: String, default: '金型人' },
    shapeKeyword: { type: String, default: '紀律分明' }
  },
  computed: {
    viewBox: function() {
      return (
        this.faceLeft +
        ' ' +
        this.faceTop +
        ' ' +
        this.faceWidth +
        ' ' +
        this.faceHeight
      )
    },
    userImageCenter: function() {
      let imgCenterX = this.faceLeft + this.faceWidth / 2
      let imgCenterY = this.faceTop + this.faceHeight / 2
      return { x: imgCenterX, y: imgCenterY }
    },
    userImageRatio: function() {
      let ratio = 1
      if (this.faceWidth > this.faceHeight) {
        ratio = (CR * 2) / this.faceHeight
      } else {
        ratio = (CR * 2) / this.faceWidth
      }
      return ratio
    }
  }
}
</script>

<style scoped>
.st0 {
  fill: #f4cb42;
  stroke: #f4cb42;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #2c2b5a;
}

.st2 {
  font-family: 'TaipeiSansTCBeta-Bold';
}

.st3 {
  font-size: 16px;
}

.st4 {
  fill: #ffffff;
}

.st5 {
  font-size: 27px;
}

.st6 {
  fill: none;
  stroke: #f4cb42;
  stroke-width: 6;
  stroke-miterlimit: 10;
}
/* .st0 {
  fill: #f4cb42;
  stroke: #f4cb42;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #2c2b5a;
}
.st2 {
  font-family: 'TaipeiSansTCBeta-Bold';
}
.st3 {
  font-size: 16px;
}
.st4 {
  fill: #ffffff;
}
.st5 {
  font-size: 27px;
}
.st6 {
  stroke: #f4cb42;
  stroke-width: 6;
  stroke-miterlimit: 10;
} */
</style>
